<template>
  <div>
    <div class="table-header">
      <div class="index">操作</div>
      <div class="actions">编辑</div>
      <div class="term">检索式</div>
      <div class="sum">检索结果</div>
      <div class="time">{{type==='usual'?'添加时间':'检索时间'}}</div>
      <!-- <div class="subscribe hd">邮件订阅</div> -->
      <div class="setting">
        <span class="fa fa-cog"></span>
        <ul>
          <li @click.stop="handleBatchSelect">{{showBatchDelete?'隐藏选择':'批量选择'}}</li>
          <li :class="{disabled: !(selectedList && selectedList.length)}" @click.stop="handleBatchDelete">确定删除</li>
          <li v-if="type==='usual'" @click.stop="handleSort">{{showSorts?'隐藏排序':'排序'}}</li>
        </ul>
      </div>
      <div class="batch-delete" v-show="showBatchDelete">
        <input type="checkbox" v-model="selectedAll">
      </div>
    </div>
    <div class="table-body">
      <div class="table-item empty-results" v-if="!data.length">{{type==='usual'?'无常用检索纪录':'无检索历史纪录'}}</div>
      <template v-else>
        <div class="table-item" v-for="(item, index) in data" :key="item.id">
          <div class="index" @click.stop="handleIndex(item, $event)"><a style="text-decoration:underline;">#{{index+1}}&equiv;</a></div>
          <div class="actions">
            <span class="search" style="text-decoration:underline;" @click.stop="handleAdd(item)">Add</span>
          </div>
          <div class="term">{{item.term}}</div>
          <div class="sum" style="text-decoration:underline;" @click.stop="handleSearchDirect(item)">{{item.sum}}</div>
          <div class="time">{{item.createDate | formatTime}}</div>
          <!-- <div class="subscribe" style="text-decoration:underline;color:#555;" @click.stop="handleSubscribe(item)">{{item.subscribe ? '取消订阅' : '订阅'}}</div> -->
          <div class="sort-up-down" v-show="showSorts">
            <span v-if="index>0" class="sort-icon fa fa-long-arrow-up" :class="{active: item.id===activeId && activeType==='up'}" @click.stop="sortUp(item, index)"></span>
            <span v-if="index<data.length-1" class="sort-icon fa fa-long-arrow-down" :class="{active: item.id===activeId && activeType==='down'}"  @click.stop="sortDown(item, index)"></span>
          </div>
          <div class="batch-delete" v-show="showBatchDelete">
            <input type="checkbox" :value="item.id" v-model="selectedList">
          </div>
        </div>
      </template>
    </div>
    <search-history-action-win :show="showActionWin" @on-hide="showActionWin=false"
      :top="top" :left="left"
      :actionType="type"
      @on-and="handleAnd"
      @on-or="handleOr"
      @on-not="handleNot"
      @on-search="handleSearch"
      @on-usual-add="handleAddHistoryUsual"
      @on-usual-delete="handleDeleteHistoryUsual"
      @on-delete="handleDelete"></search-history-action-win>
    <tooltip-win :show="showTipWin" @on-hide="showTipWin=false" :duration="500" :text="tipText" :tipType="tipType"></tooltip-win>
  </div>
</template>
<script>
import dateFilter from '../common/mixins/dateFilter'
import TooltipWin from '../login-tip-win/tooltip-win'
import SearchHistoryActionWin from './search-history-action-win'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  mixins: [dateFilter],
  components: {TooltipWin, SearchHistoryActionWin},
  props: {
    type: {
      type: String,
      default: 'history'  // usual
    },
    data: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      showActionWin: false,
      top: 0,
      left: 0,
      currentItem: {},
      selectedAll: false,
      selectedList: [],
      showBatchDelete: false,
      showSorts: false,
      activeId: '',
      activeType: '',
      showTipWin: false,
      tipType: 'warning',
      tipText: '',
      selectedByItem: false
    }
  },
  watch: {
    selectedAll (val) {
      if (val) {
        this.selectedList = this.data.map(item => item.id)
      } else if (this.selectedList.length === this.data.length) {
        this.selectedList = []
      }
    },
    selectedList (val) {
      if (val && val.length === this.data.length) {
        this.selectedAll = true
      } else if (val && val.length < this.data.length) {
        this.selectedAll = false
      }
    }
  },
  methods: {
    handleIndex (item, e) {
      this.currentItem = item
      this.showActionWin = true
      this.left = e.clientX + 10
      this.top = e.clientY
      if (window.innerHeight - e.clientY < 150) {
        this.top = window.innerHeight - 150
      }
    },
    handleAdd (item) {
      this.$emit('on-and', item.term)
    },
    handleSearchDirect (item) {
      this.$emit('on-search', item.term)
    },
    handleAnd () {
      this.$emit('on-and', this.currentItem.term)
    },
    handleOr () {
      this.$emit('on-or', this.currentItem.term)
    },
    handleNot () {
      this.$emit('on-not', this.currentItem.term)
    },
    handleSearch () {
      this.$emit('on-search', this.currentItem.term)
    },
    handleAddHistoryUsual () {
      this.$emit('on-add-usual', this.currentItem)
    },
    handleDelete () {
      this.$emit('on-delete', this.currentItem.id)
    },
    handleDeleteHistoryUsual () {
      this.$emit('on-delete-usual', this.currentItem.id)
    },
    handleBatchSelect () {
      if (this.showSorts) this.showSorts = false
      this.showBatchDelete = !this.showBatchDelete
    },
    handleBatchDelete () {
      if (!this.selectedList.length) {
        this.tipText = '请选择'
        this.tipType = 'warning'
        this.showTipWin = true
      } else {
        this.batchDelete()
      }
    },
    handleSubscribe (item) {
      this.subscribeTerm(item)
    },
    handleSort () {
      if (this.showBatchDelete) this.showBatchDelete = false
      this.showSorts = !this.showSorts
    },
    sortUp (item, index) {
      if (index > 0) {
        this.activeId = item.id
        this.activeType = 'up'
        let temps = this.data.splice(index - 1, 1)
        if (temps && temps.length) {
          this.data.splice(index, 0, temps[0])
          this.sortData()
        }
      }
    },
    sortDown (item, index) {
      if (index < this.data.length - 1) {
        this.activeId = item.id
        this.activeType = 'down'
        let temps = this.data.splice(index, 1)
        if (temps && temps.length) {
          this.data.splice(index + 1, 0, temps[0])
          this.sortData()
        }
      }
    },
    sortData () {
      let idList = this.data.map(item => item.id)
      this.$http.post(`${this.httpRoot}/search/history/usual/sort`, {ids: idList}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          // this.showTipWin = true
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    batchDelete () {
      let path = 'search/history/usual/batchdelete'
      if (this.type === 'history') {
        path = 'search/history/batchdelete'
      }
      this.$http.post(`${this.httpRoot}/${path}`, {ids: this.selectedList}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showBatchDelete = false
          this.selectedAll = false
          this.selectedList = []
          if (this.type === 'history') {
            this.$parent.loadHistoryList()
          } else {
            this.$parent.loadHistoryUsualList()
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    subscribeTerm (item) {
      // success, toast subscribe result
      const action = item.subscribe ? 'unsubscribe' : 'subscribe'
      this.$http.post(`${this.httpRoot}/search/history/${action}`, {id: item.id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          item.subscribe = !item.subscribe
          // Message.success(action === 'subscribe' ? '订阅成功' : '取消订阅成功')
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    hasEmail (callback) {
      this.$http.get(`${this.httpRoot}/auth/email/valid`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (typeof callback === 'function') {
            callback()
          }
        } else if (res.emailValid === 'null') {
          this.$parent.showEmailWin = true
        } else if (res.emailValid === 'false') {
          this.$parent.emailFailed(res.email)
        } else {
          this.$parent.fulltextHelpFailed(res.statusText)
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
</script>
