<template>
  <div class="adv-search-history">
    <div class="header-table-title">
      <span @click="showUsual=false" class="head-history" :class="{active: !showUsual}">检索历史</span>
      <span @click="onUsualClick" class="head-usual" :class="{active: showUsual}">常用检索</span>
    </div>
    <div class="history-table" v-show="!showUsual">
      <history-table
        type="history"
        :data="historyList"
        @on-and="handleAnd"
        @on-or="handleOr"
        @on-not="handleNot"
        @on-search="handleSearch"
        @on-add-usual="handleAddUsual"
        @on-delete="handleDelete"
        @on-delete-usual="handleDeleteUsual"></history-table>
    </div>
    <div class="history-table-usual" v-show="showUsual">
      <history-table
        type="usual"
        :data="usualList"
        @on-and="handleAnd"
        @on-or="handleOr"
        @on-not="handleNot"
        @on-search="handleSearch"
        @on-add-usual="handleAddUsual"
        @on-delete="handleDelete"
        @on-delete-usual="handleDeleteUsual"></history-table>
    </div>
    <tooltip-win :show="showTipWin" @on-hide="showTipWin=false" text="请先登录"></tooltip-win>
  </div>
</template>
<script>
import usual from './usual'
import HistoryTable from './history-table'
import TooltipWin from '../login-tip-win/tooltip-win'
import { authenticate, catchExpired } from '../../utils/auth'
import { getStorageItem, updateStorageItem } from '../../utils/cache'
export default {
  mixins: [usual],
  components: {HistoryTable, TooltipWin},
  data () {
    return {
      historyList: [],
      showUsual: false,
      showTipWin: false
    }
  },
  computed: {
    loggedIn () {
      let auth = getStorageItem('auth')
      let loggedAuth = this.$store.state.auth.loggedAuth
      let isLogin = this.$store.getters.getLoginState
      return auth && loggedAuth && isLogin
    }
  },
  mounted () {
    this.loadHistoryList()
    if (this.loggedIn) {
      this.loadHistoryUsualList()
    }
  },
  methods: {
    loadHistoryList () {
      this.$http.get(`${this.httpRoot}/search/history/list`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.historyList = res.historyList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    deleteHistory (id) {
      this.$http.post(`${this.httpRoot}/search/history/delete`, {id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.loadHistoryList()
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    handleAnd (term) {
      this.$emit('on-and', term)
    },
    handleOr (term) {
      this.$emit('on-or', term)
    },
    handleNot (term) {
      this.$emit('on-not', term)
    },
    handleSearch (term) {
      this.$emit('on-search', term)
    },
    handleAddUsual (item) {
      // 判断是否登录
      if (!this.$parent.loggedIn) {
        updateStorageItem('route', {name: 'paperSearchAdvance'})
        this.$parent.showLoginTipWin = true
        return
      }
      this.addHistoryUsual(item)
    },
    handleDelete (id) {
      this.deleteHistory(id)
    },
    handleDeleteUsual (id) {
      this.deleteHistoryUsual(id)
    },
    onUsualClick () {
      if (this.loggedIn) {
        this.showUsual = true
      } else {
        this.showTipWin = true
      }
    }
  }
}
</script>
<style lang="less">
.adv-search-history {
  font-size: 0.8125rem;
}
.header-table-title {
  padding: 0.5rem;
}
.table-header, .table-body {
  * {
    box-sizing: border-box;
  }
  .index {
    width: 6%;
    float: left;
    text-align: center;
    cursor: pointer;
  }
  .term {
    width: 68%;
    padding-left: 0.25rem;
    overflow: hidden;
    float: left;
  }
  .sum {
    width: 9%;
    padding-right: 0.5rem;
    float: left;
    text-align: right;
    cursor: pointer;
  }
  .time {
    width: 11%;
    padding-left: 0.25rem;
    float: left;
  }
  .actions {
    width: 6%;
    text-align: center;
    float: left;
    &> span {
      cursor: pointer;
      padding: 0 2px;
      &:hover {
        color: #0071bc;
      }
    }
  }
  .subscribe {
    position: relative;
    width: 6%;
    text-align: left;
    float: left;
    cursor: pointer;
    &.hd {
      left: -16px;
    }
  }
  .setting {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    height: 1.8125rem;
    line-height: 1.8125rem;
    text-align: right;
    cursor: pointer;
    .fa {
      margin: 0 10px;
    }
    ul {
      display: none;
    }
    &:hover {
      ul {
        display: block;
        background-color: #fff;
        box-shadow: 0 0 5px rgba(0,0,0, 0.25);
        li {
          list-style: none;
          text-align: left;
          padding: 0 5px;
          &:hover {
            background-color: #eee;
          }
          &.disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }
  .batch-delete {
    position: absolute;
    z-index: 6;
    width: 50px;
    right: -60px;
    top: 50%;
    transform: translateY(-50%);
  }
  &:after {
    content: '.';
    height: 0;
    display: block;
    visibility: hidden;
    clear: both;
  }
}
.table-header {
  position: relative;
  background: #eaecef;
  border: 1px solid #cecece;
  height: 1.8125rem;
  line-height: 1.8125rem;
  border-bottom: 0;
}
.table-body {
  border: 1px solid #cecece;
  border-top: 0;
}
.table-item {
  // height: 1.8125rem;
  line-height: 1.8125rem;
  border-top: 1px solid #f1f1f1;
  position: relative;
  &:after {
    content: '.';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
  .sort-up-down {
    position: absolute;
    z-index: 6;
    width: 50px;
    right: -55px;
    top: 50%;
    transform: translateY(-50%);
  }
  .sort-icon {
    cursor: pointer;
    padding: 0 7px;
    &:hover {
      color: #3366ff;
    }
    &.active {
      background: #ff5f5f;
      border-radius: 50%;
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
  .batch-delete {
    position: absolute;
    z-index: 6;
    width: 50px;
    right: -60px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.empty-results {
  text-align: center;
}
.head-history,
.head-usual {
  cursor: pointer;
  &.active {
    color: #0053a6;
  }
}
.head-usual {
  margin-left: 10px;
}
</style>
