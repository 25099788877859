<template>
  <div class="query-item-line">
    <select class="relation-item adv-search-field" v-model="relation" :style="{visibility:index>0?'relationStyle':'hidden'}">
      <option value="AND">AND</option>
      <option value="OR">OR</option>
      <option value="NOT">NOT</option>
    </select>
    <select class="query-item adv-search-field" v-model="field">
      <option value="Affiliation">Affiliation</option>
      <option value="All Fields" selected="selected">All Fields</option>
      <option value="Author">Author</option>
      <option value="Author - Corporate">Author - Corporate</option>
      <option value="Author - First">Author - First</option>
      <option value="Author - Identifier">Author - Identifier</option>
      <option value="Author - Last">Author - Last</option>
      <option value="Book">Book</option>
      <option value="Conflict of Interest Statements">Conflict of Interest Statements</option>
      <option value="Date - Completion">Date - Completion</option>
      <option value="Date - Create">Date - Create</option>
      <option value="Date - Entrez">Date - Entrez</option>
      <option value="Date - MeSH">Date - MeSH</option>
      <option value="Date - Modification">Date - Modification</option>
      <option value="Date - Publication">Date - Publication</option>
      <option value="EC/RN Number">EC/RN Number</option>
      <option value="Editor">Editor</option>
      <option value="Filter">Filter</option>
      <option value="Grant Number">Grant Number</option>
      <option value="ISBN">ISBN</option>
      <option value="Investigator">Investigator</option>
      <option value="Issue">Issue</option>
      <option value="Journal">Journal</option>
      <option value="Language">Language</option>
      <option value="Location ID">Location ID</option>
      <option value="MeSH Major Topic">MeSH Major Topic</option>
      <option value="MeSH Subheading">MeSH Subheading</option>
      <option value="MeSH Terms">MeSH Terms</option>
      <option value="Other Term">Other Term</option>
      <option value="Pagination">Pagination</option>
      <option value="Pharmacological Action">Pharmacological Action</option>
      <option value="Publication Type">Publication Type</option>
      <option value="Publisher">Publisher</option>
      <option value="Secondary Source ID">Secondary Source ID</option>
      <option value="Subject - Personal Name">Subject - Personal Name</option>
      <option value="Supplementary Concept">Supplementary Concept</option>
      <option value="Text Word">Text Word</option>
      <option value="Title">Title</option>
      <option value="Title/Abstract">Title/Abstract</option>
      <option value="Transliterated Title">Transliterated Title</option>
      <option value="Volume">Volume</option>
    </select>
    <div class="query-content" v-if="!isFieldDateRange">
      <input class="query-input" v-model="term" placeholder="输入检索条件回车查询" @keyup.enter="handleEnter">
      <tooltip :show="showInputWarnTip" @on-hide="showInputWarnTip=false" autoClose place="bottom">请输入检索条件内容</tooltip>
    </div>
    <div class="query-content" v-else>
      <div class="date-start">
        <input class="query-input" placeholder="YYYY/MM/DD" v-model="dateStart" @keyup.enter="handleEnter">
        <tooltip :show="showDateStartNeedTip" @on-hide="showDateStartNeedTip=false" autoClose place="bottom">请输入开始日期</tooltip>
        <tooltip :show="showDateStartFormatWarnTip" @on-hide="showDateStartFormatWarnTip=false" autoClose place="bottom">请输入正确格式的日期，如：2018/08/09</tooltip>
        <tooltip :show="showDateRangeWarnTip" @on-hide="showDateRangeWarnTip=false" autoClose place="bottom">开始日期超过了结束日期</tooltip>
      </div>
      <span style="padding: 0 7px;line-height: 24px;">to</span>
      <div class="date-end">
        <input class="query-input" placeholder="YYYY/MM/DD" v-model="dateEnd" @keyup.enter="handleEnter">
        <tooltip :show="showDateEndNeedTip" @on-hide="showDateEndNeedTip=false" autoClose place="bottom">请输入结束日期</tooltip>
        <tooltip :show="showDateEndFormatWarnTip" @on-hide="showDateEndFormatWarnTip=false" autoClose place="bottom">请输入正确格式的日期，如：2018/08/09</tooltip>
      </div>
    </div>
    <div class="actions">
      <span class="fa fa-minus-circle" v-if="canRemove" @click.stop="handleRemove"></span>
      <span class="fa fa-plus-circle" v-if="isLast" @click.stop="handleAdd"></span>
    </div>
  </div>
</template>
<script>
import Tooltip from '../common/tooltip/tooltip'
export default {
  components: {Tooltip},
  props: {
    index: [String, Number], // 0, 1, 2
    query: {
      type: Object,
      default () { return {} }
    },
    canRemove: {
      type: Boolean,
      default: false
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      relation: this.query.relation || 'AND',
      field: this.query.field || 'All Fields',
      term: this.query.term || '',
      dateStart: '',
      dateEnd: this.getToday(),

      isFieldDateRange: false,

      showInputWarnTip: false,
      showDateStartNeedTip: false,
      showDateStartFormatWarnTip: false,
      showDateRangeWarnTip: false,
      showDateEndNeedTip: false,
      showDateEndFormatWarnTip: false
    }
  },
  watch: {
    relation (val) {
      this.emitChange()
    },
    field (val, oldVal) {
      if (val && val.indexOf('Date') === 0) {
        this.isFieldDateRange = true
        if (this.term) {
          this.term = ''
        } else {
          this.emitChange()
        }
      } else {
        this.isFieldDateRange = false
        if (oldVal && oldVal.indexOf('Date') === 0 && this.term) {
          this.term = ''
        } else {
          this.emitChange()
        }
      }
    },
    term (val) {
      this.emitChange()
    },
    dateStart (val) {
      this.mergeDateTerm()
    },
    dateEnd (val) {
      this.mergeDateTerm()
    },
    query (val) {
      if (val.relation) {
        this.relation = val.relation
      }
      if (val.field) {
        this.field = val.field
      }
      if (val.term) {
        this.term = val.term
      }
    }
  },
  methods: {
    handleEnter () {
      this.$emit('on-query-search')
    },
    handleRemove () {
      this.$emit('on-query-remove', this.index)
    },
    handleAdd () {
      this.$emit('on-query-add')
    },
    emitChange () {
      this.$emit('on-query-change', {query: {key: this.query.key, relation: this.relation, field: this.field, term: this.term}, index: this.index})
    },
    mergeDateTerm () {
      this.term = '("' + this.dateStart + '"[' + this.field + ']:"' + this.dateEnd + '"[' + this.field + '])'
    },
    valdateDateTerm () {
      if (this.isFieldDateRange) {
        // 开始判断开始日期
        if (!this.dateStart) {
          this.showDateStartNeedTip = true
        } else if (this.dateStart && !this.isDate(this.dateStart)) {
          this.showDateStartFormatWarnTip = true
        } else if (!this.dateEnd) {
          // 现在开始判断结束日期
          this.showDateEndNeedTip = true
        } else if (this.dateEnd && !this.isDate(this.dateEnd)) {
          this.showDateEndFormatWarnTip = true
        } else if (!this.isDateRange(this.dateStart, this.dateEnd)) {
          this.showDateRangeWarnTip = true
        } else {
          return true
        }
        return false
      }
      return true
    },
    isDateRange (start, end) {
      start = start.split('/')
      end = end.split('/')
      start = start.map(item => Number(item))
      end = end.map(item => Number(item))
      if (start.length === 3 && end.length === 3) {
        if (start[0] > end[0]) {
          return false
        } else if (start[1] > end[1]) {
          return false
        } else if (start[2] > end[2]) {
          return false
        } else {
          return true
        }
      }
      return false
    },
    isDate (str) {
      return /^\d{4}\/\d{2}\/\d{2}$/.test(str) && /((19\d{2}|20\d{2})\/(0[1-9]{1}|1[0,2]{1})\/(0[1-9]{1}|[1,2]{1}[0-9]{1}|3[0,1]{1}))/.test(str)
    },
    getToday () {
      let year = new Date().getFullYear()
      let month = new Date().getMonth() + 1
      let day = new Date().getDate()
      if (month < 10) {
        month = '0' + month
      }
      return year + '/' + month + '/' + day
    }
  }
}
</script>
<style lang="less">
.query-item-line {
  display: flex;
  margin: 5px 0;
  .adv-search-field {
    margin-right: 0.4rem;
    height: 1.5rem;
    border: 1px solid #aeb0b5;
    outline: 0;
    padding: 0 0.25rem;
    &:focus {
      border-color: #337cd6;
    }
  }
  .query-content {
    position: relative;
    height: 1.5rem;
    width: 40rem;
    display: flex;
    &> .clear-btn-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 80%;
      height: 100%;
      box-shadow: -0.4rem 0 0.6rem -0.3rem rgba(0,0,0,0.1);
    }
    &>.date-start, &>.date-end {
      position: relative;
      display: flex;
      flex: 1 0 auto;
    }
  }
  .query-input {
    flex: 1 0 auto;
    display: inline-block;
    border: 1px solid #aeb0b5;
    padding: 0 0.5rem;
    outline: 0;
    &:focus {
      border-color: #337cd6;
    }
  }
  .actions {
    &>.fa {
      text-align: center;
      width: 24px;
      height: 24px;
      line-height: 24px;
      color: #888;
      cursor: pointer;
    }
  }
}
</style>
