<template>
  <transition name="fade">
    <div :class="['cu-tooltip', place]" v-show="showTooltip">
      <span class="waring fa fa-warning"></span>
      <slot></slot>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showTime: {
      type: Number,
      default: 2000
    },
    top: {
      type: [Number, String],
      default: 0
    },
    left: {
      type: [Number, String],
      default: 0
    },
    place: {
      type: String,
      default: 'bottom'
    },
    autoClose: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showTooltip: !!this.show
    }
  },
  watch: {
    show (val) {
      this.showTooltip = !!val
      if (val) {
        this.close()
      }
    }
  },
  methods: {
    close () {
      if (!this.autoClose) return
      let delayTime = Number(this.showTime) || 2000
      setTimeout(() => {
        this.showTooltip = false
        this.$emit('on-hide')
      }, delayTime)
    }
  }
}
</script>
<style lang="less">
.cu-tooltip {
  position: absolute;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #fff;
  padding: 5px 8px;
  padding-left: 25px;
  height: 18px;
  font-size: 13px;
  z-index: 9999;
  &.bottom {
    left: 0;
    bottom: -7px;
    transform: translate(0, 100%);
    box-shadow: 0 1px 3px rgba(33,33,33,0.45);
    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      left: 30px;
      width: 8px;
      height: 8px;
      background-color: #fff;
      border-left: 1px solid #333;
      border-top: 1px solid #333;
      transform: translate(0, -60%) rotate(45deg);
    }
  }
  &.top {
    top: -7px;
    bottom: 0;
    transform: translate(0, -100%);
    box-shadow: 0 -1px 3px rgba(33,33,33,0.45);
    &:before {
      position: absolute;
      content: '';
      display: block;
      bottom: 0;
      left: 30px;
      width: 8px;
      height: 8px;
      background-color: #fff;
      border-right: 1px solid #333;
      border-bottom: 1px solid #333;
      transform: translate(0, 55%) rotate(45deg);
    }
  }
  &.left {
    top: 50%;
    left: -7px;
    transform: translate(-100%, -50%);
    box-shadow: -1px 0 3px rgba(33,33,33,0.45);
    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 50%;
      right: 0;
      width: 8px;
      height: 8px;
      background-color: #fff;
      border-top: 1px solid #333;
      border-right: 1px solid #333;
      transform: translate(55%, -50%) rotate(45deg);
    }
  }
  &.right {
    top: 50%;
    right: -7px;
    transform: translate(100%, -50%);
    box-shadow: 1px 0 3px rgba(33,33,33,0.45);
    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 50%;
      left: 0;
      width: 8px;
      height: 8px;
      background-color: #fff;
      border-left: 1px solid #333;
      border-bottom: 1px solid #333;
      transform: translate(-55%, -50%) rotate(45deg);
    }
  }
  &> .waring {
    position: absolute;
    top: 7px;
    left: 7px;
    width: 14px;
    height: 14px;
    color: rgb(255, 209, 58);
  }
}
</style>
