<template>
  <modal v-model="showModal" transparent maskClose hideClose moveable :top="top" :left="left">
    <div class="action-list">
      <div class="action" @click="handleAnd">AND 检索式</div>
      <div class="action" @click="handleOr">OR 检索式</div>
      <div class="action" @click="handleNot">NOT 检索式</div>
      <div class="action" @click="handleSearch">直接检索</div>
      <div class="action" @click="handleUsual" v-if="actionType==='history'">加入常用</div>
      <div class="action" @click="handleDelete" v-if="actionType==='history'">删除</div>
      <div class="action" @click="handleDeleteUsual" v-if="actionType==='usual'">删除</div>
    </div>
  </modal>
</template>
<script>
import Modal from '../common/modal/modal'
export default {
  components: { Modal },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    top: {
      type: Number,
      default: 0
    },
    left: {
      type: Number,
      default: 0
    },
    actionType: String
  },
  data () {
    return {
      showModal: this.show
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide')
      }
    }
  },
  methods: {
    handleAnd () {
      this.$emit('on-and')
      this.close()
    },
    handleOr () {
      this.$emit('on-or')
      this.close()
    },
    handleNot () {
      this.$emit('on-not')
      this.close()
    },
    handleSearch () {
      this.$emit('on-search')
      this.close()
    },
    handleUsual () {
      this.$emit('on-usual-add')
      this.close()
    },
    handleDelete () {
      this.$emit('on-delete')
      this.close()
    },
    handleDeleteUsual () {
      this.$emit('on-usual-delete')
      this.close()
    },
    close () {
      setTimeout(() => {
        this.showModal = false
      }, 100)
    }
  }
}
</script>
<style lang="less">
.action-list {
  .action {
    padding: 0 15px;
    height: 28px;
    line-height: 28px;
    cursor: pointer;
    &:hover {
      background: #ddd;
    }
  }
}
</style>
