<template>
  <div>
    <div class="paper-search-ad page-box">
      <search-header></search-header>
      <div class="query-box-section-wrapper">
        <div class="query-box-wrapper">
          <div v-if="!queryTermEditStatus" class="query-box-input">{{queryTerm}}</div>
          <textarea v-else class="query-box-textarea" v-model="queryTermEdit" placeholder="编辑或从检索历史中添加检索式"></textarea>
        </div>
        <div class="query-box-actions">
          <span v-if="!queryTermEditStatus" @click.stop="handleQueryEdit">编辑</span>
          <span v-if="queryTermEditStatus" @click.stop="handleQueryEditCancel">取消编辑</span>
          <span style="float:right" @click.stop="clearQueryTerm">清空</span>
        </div>
      </div>
      <div class="term-builder-wrapper" v-if="!queryTermEditStatus">
        <div class="query-head">
          <span class="query-title">编制检索式：</span>
        </div>
        <query-item v-for="(item, index) in queryTermList" :key="item.key" :index="index"
          ref="queryItem"
          :query="item"
          :canRemove="queryTermList.length>1"
          :isLast="index===queryTermList.length-1"
          @on-query-change="onQueryTermChange"
          @on-query-add="onQueryTermAdd"
          @on-query-remove="onQueryTermRemove"
          @on-query-search="onQuerySearch"></query-item>
      </div>
      <div class="query-search">
        <button class="search-btn" @click.stop="handleSearch">检索</button>
        <!-- <a class="btn" style="text-decoration:underline;cursor:pointer;">添加到历史</a> -->
      </div>
      <div class="query-box-history">
        <search-history @on-and="onTermAnd" @on-or="onTermOr" @on-not="onTermNot" @on-search="onTermSearch"></search-history>
      </div>
    </div>
    <login-tip-win2 :show="showLoginTipWin" @on-hide="showLoginTipWin=false" text="登陆后可操作"></login-tip-win2>
  </div>
</template>
<script>
import SearchHeader from './search-header'
import SearchHistory from './search-history'
import QueryItem from './query-item'
import LoginTipWin2 from '../login-tip-win/login-tip-win2'
import Login from '../../utils/login'
import { getStorageItem } from '../../utils/cache'
export default {
  components: { SearchHeader, SearchHistory, QueryItem, LoginTipWin2 },
  mixins: [Login],
  data () {
    return {
      queryTermList: [
        {key: new Date().getTime().toString(), relation: '', field: '', term: ''},
        {key: new Date().getTime().toString() + 1, relation: '', field: '', term: ''}
      ],
      queryTerm: '',
      queryTermEdit: '',
      queryTermEditStatus: false,

      isFieldDateRange: false,

      showDateStartNeedTip: false,
      showDateStartFormatWarnTip: false,
      showDateEndNeedTip: false,
      showDateEndFormatWarnTip: false,
      showDateRangeWarnTip: false,

      showLoginTipWin: false
    }
  },
  computed: {
    loggedIn () {
      let auth = getStorageItem('auth')
      let loggedAuth = this.$store.state.auth.loggedAuth
      let isLogin = this.$store.getters.getLoginState
      return auth && loggedAuth && isLogin
    }
  },
  methods: {
    handleQueryEdit () {
      this.queryTermEditStatus = true
    },
    handleQueryEditCancel () {
      this.queryTermEditStatus = false
      this.queryTermEdit = this.queryTerm
    },
    onQueryTermChange ({query, index}) {
      console.log(index, query)
      this.queryTermList.splice(index, 1, query)
      let lastTermIndex = -1
      this.queryTermList.forEach((item, index) => {
        if (item.term) {
          lastTermIndex = index
        }
      })
      if (lastTermIndex === this.queryTermList.length - 1) {
        this.queryTermList.push({key: new Date().getTime().toString(), relation: '', field: '', term: ''})
      }
      this.updateQueryTerm()
    },
    onQueryTermAdd () {
      this.queryTermList.push({key: new Date().getTime().toString(), relation: '', field: '', term: ''})
    },
    onQueryTermRemove (index) {
      this.queryTermList.splice(index, 1)
      this.updateQueryTerm()
    },
    onQuerySearch () {
      this.handleSearch()
    },
    clearQueryTerm () {
      this.queryTerm = ''
      this.queryTermEdit = ''
      this.queryTermList = []
      setTimeout(() => {
        this.queryTermList = [
          {key: new Date().getTime().toString(), relation: '', field: '', term: ''},
          {key: new Date().getTime().toString() + 1, relation: '', field: '', term: ''}
        ]
      }, 0)
    },
    onTermAnd (term) {
      this.handleRelationHistory('AND', term)
    },
    onTermOr (term) {
      this.handleRelationHistory('OR', term)
    },
    onTermNot (term) {
      this.handleRelationHistory('NOT', term)
    },
    onTermSearch (term) {
      this.queryTerm = term
      this.handleSearch()
    },
    updateQueryTerm () {
      let queryTerm = ''
      this.queryTermList.forEach(item => {
        if (item.term) {
          if (queryTerm) {
            if (item.field === 'All Fields' || item.field.indexOf('Date') === 0) {
              queryTerm = '(' + queryTerm + ') ' + item.relation + ' (' + item.term + ')'
            } else {
              queryTerm = '(' + queryTerm + ') ' + item.relation + ' (' + item.term + '[' + item.field + '])'
            }
          } else {
            if (item.field === 'All Fields' || item.field.indexOf('Date') === 0) {
              queryTerm = item.term
            } else {
              queryTerm = item.term + '[' + item.field + ']'
            }
          }
        }
      })
      this.queryTerm = queryTerm
      this.queryTermEdit = queryTerm
    },
    handleRelationHistory (relation, term) {
      let lastTermIndex = -1
      this.queryTermList.forEach((item, index) => {
        if (item.term) {
          lastTermIndex = index
        }
      })
      if (lastTermIndex < this.queryTermList.length - 1) {
        // 有空的term
        this.queryTermList.splice(lastTermIndex + 1, 1, {key: new Date().getTime().toString(), relation: relation, field: 'All Fields', term: term})
        if (lastTermIndex === this.queryTermList.length - 2) {
          this.queryTermList.push({key: new Date().getTime().toString() + 1, relation: '', field: '', term: ''})
        }
      } else if (lastTermIndex === this.queryTermList.length - 1) {
        this.queryTermList.push({key: new Date().getTime().toString(), relation: relation, field: 'All Fields', term: term})
        this.queryTermList.push({key: new Date().getTime().toString() + 1, relation: '', field: '', term: ''})
      }
      this.updateQueryTerm()
    },
    handleSearch () {
      if (this.queryTerm) {
        let queryItems = this.$refs.queryItem
        let valid = true
        if (queryItems && queryItems.length) {
          queryItems.forEach(item => {
            if (!item.valdateDateTerm()) {
              valid = false
            }
          })
        }
        if (valid) {
          this.$router.push({name: 'paperSearch', query: {term: this.queryTerm}})
        }
      }
    }
  }
}
</script>
<style lang="less">
.paper-search-ad {
  font-size: 0.8125rem;
}
.query-head {
  display: flex;
}
.query-box-section-wrapper {
  margin-bottom: 1rem;
}
.query-box-actions {
  span {
    cursor: pointer;
    &:hover {
      color: #1354a3;
    }
  }
}
.query-box-input {
  border: 1px solid #ccc;
  background: #eee;
  min-height: 28px;
}
.query-box-textarea {
  height: 2rem;
  width: 66.5rem;
  padding: 0.25rem;
}
.query-search {
  margin-top: 10px;
  &> button {
    margin-right: 1rem;
    padding: 0 1.5rem;
    white-space: nowrap;
    height: 1.7rem;
    width: auto;
    border: 0;
    background: linear-gradient(to bottom, #337cd6, #3975bd, #337cd6);
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
    &:hover {
      background: #2865af;
    }
    &:active {
      background: #205493;
    }
  }
}
.query-box-history {
  margin-top: 1.2rem;
}
</style>
