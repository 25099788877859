import { authenticate, catchExpired } from '../../utils/auth'

export default {
  data () {
    return {
      usualList: []
    }
  },
  methods: {
    loadHistoryUsualList () {
      this.$http.get(`${this.httpRoot}/search/history/usual/list`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.usualList = res.usualList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    addHistoryUsual (item) {
      this.$http.post(`${this.httpRoot}/search/history/usual/add`, {searchFormula: item}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.loadHistoryUsualList()
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    deleteHistoryUsual (id) {
      this.$http.post(`${this.httpRoot}/search/history/usual/delete`, {id}, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.loadHistoryUsualList()
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    }
  }
}
